// Used in gatsby node which doest not support es6 imports

/**
 * List of the support language code we can use in our site.
 * it follows the standard IETF language tag format, which is :
 * [ISO 639 2-digits language code]-[ISO 3166 country code]
 */
const LANG_DE_DE = "de-DE";
const LANG_FR_BE = "fr-BE";
const LANG_FR_FR = "fr-FR";
const LANG_EN_US = "en-US";
const LANG_FR_CA = "fr-CA";
const LANG_EN_CA = "en-CA";
// Catch-all lang to use if no other way, no country code specified on purpose
const LANG_EN = "en";

/**
 * Base path per locale
 */
const LANGUAGE_TO_HOMEPAGE = Object.freeze({
  [LANG_FR_FR]: "/",
  [LANG_FR_BE]: "/be",
  [LANG_DE_DE]: "/de",
  [LANG_EN_US]: "/us",
  [LANG_FR_CA]: "/ca/fr",
  [LANG_EN_CA]: "/ca/en",
});

const localizePath = ({ locale, path }) =>
  (LANGUAGE_TO_HOMEPAGE[locale] + "/" + path).replaceAll("//", "/");

const blogFolder = {
  en: "news",
  "fr-FR": "actualites",
  "fr-BE": "actualites",
  "fr-CA": "actualites",
  "de-DE": "aktuelles",
};
const pressFolder = {
  en: "press",
  "fr-FR": "presse",
  "fr-BE": "presse",
  "fr-CA": "presse",
};

const infoFolder = {
  en: "info",
};

const blogPath = ({ locale, slug }) => {
  const folder = blogFolder[locale] || blogFolder[LANG_EN];
  return localizePath({ locale, path: `${folder}/${slug || ""}` });
};

const pressPath = ({ locale, slug }) => {
  const folder = pressFolder[locale] || pressFolder[LANG_EN];
  return localizePath({ locale, path: `${folder}/${slug || ""}` });
};

const infoPath = ({ locale, slug }) => {
  const folder = infoFolder[locale] || infoFolder[LANG_EN];
  return localizePath({ locale, path: `${folder}/${slug || ""}` });
};

const dynPagePath = ({ locale, slug }) => {
  return localizePath({ locale, path: slug });
};

const lightBackgroundColor = "bg-transparent";
const darkBackgroundColor = "bg-gray-50";

module.exports = {
  LANG_DE_DE,
  LANG_FR_BE,
  LANG_FR_FR,
  LANG_EN_US,
  LANG_FR_CA,
  LANG_EN_CA,
  LANG_EN,
  LANGUAGE_TO_HOMEPAGE,
  blogPath,
  pressPath,
  infoPath,
  dynPagePath,
  localizePath,
  lightBackgroundColor,
  darkBackgroundColor,
};

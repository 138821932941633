import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import { t } from "../translations";
import { LANG_FR_FR } from "../constants";

const Seo = ({
  title,
  description,
  image,
  isArticle,
  locale = LANG_FR_FR,
  keywords,
}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const { titleTemplate, siteUrl, defaultImage, social } = site.siteMetadata;

  const seo = {
    title: (title && title.trim()) || t(locale, "seo.title"),
    description:
      (description && description.trim()) || t(locale, "seo.description"),
    image: image || `${siteUrl}${defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet titleTemplate={titleTemplate} title={seo.title}>
      <html lang={locale} />
      <title> {seo.title} </title>
      <meta name="image" content={seo.image} />
      <meta name="description" content={seo.description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      (// openGraph)
      <meta property="og:site_name" content="Omnidoc" />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {isArticle ? (
        <meta property="og:type" content="article" />
      ) : (
        <meta property="og:type" content="website" />
      )}
      (//twitter)
      <meta name="twitter:card" content="summary_large_image" />
      {social && social.twitter && (
        <meta name="twitter:site" content={social.twitter} />
      )}
      {social && social.twitter && (
        <meta name="twitter:creator" content={social.twitter} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {process.env.GATSBY_NOINDEX && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

Seo.propTypes = {
  locale: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  isArticle: PropTypes.bool,
  keywords: PropTypes.string,
};

Seo.defaultProps = {
  locale: `fr`,
  description: null,
  title: null,
  image: null,
  isArticle: false,
  keywords: null,
};

export { Seo };

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
        siteUrl: url
        defaultImage: image
        author
        social {
          twitter
        }
      }
    }
  }
`;

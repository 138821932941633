import {
  LANG_EN,
  LANG_FR_FR,
  LANG_FR_BE,
  LANG_DE_DE,
  LANG_FR_CA,
} from "./constants";

export const t = (locale, key) =>
  translations[locale]?.[key] || translations[LANG_EN]?.[key] || key;

export const translator = locale => key => t(locale, key);

export const Trans = ({ locale, key }) => t(locale, key);

const translations = {
  [LANG_EN]: {
    "seo.title": "eConsult Platform",
    "seo.description": "Omnidoc is an eConsult Platform",
    "navigation.open-menu": "Open menu",
    "navigation.close-menu": "Close menu",
    "news.title": "News",
    "news.seo-title": "News",
    "press.title": "Press",
    "press.download-kit": "Download our press kit",
    "press.kit": "Press kit",
    "info.title": "Information",
    "info.seo-title": "Information",
    "contact-form.title": "Request a personalized demo",
    "contact-form.form-description":
      "Our team is at your disposal to present the solution and discuss its implementation in your context.",
    "contact-form.first-name": "First name",
    "contact-form.last-name": "Last name",
    "contact-form.email": "Email",
    "contact-form.phone": "Phone",
    "contact-form.organization": "Organization",
    "contact-form.role": "Position",
    "contact-form.description": "Project",
    "contact-form.submit-text": "Get more information",
    "contact-form-thanks.title": "Noted!",
    "contact-form-thanks.formSubmitConfirm":
      "We have succesfully received your request for a demo.",
    "contact-form-thanks.backToHome": "Back to homepage",
  },
  [LANG_FR_FR]: {
    "seo.title": "Solution de téléexpertise et de e-RCP",
    "seo.description":
      "Omnidoc est une solution de téléexpertise et de e-RCP à destination des professionnels de santé et des organisations médicales qui souhaitent améliorer, sécuriser et valoriser les avis échangés.",
    "navigation.open-menu": "Ouvrir le menu",
    "navigation.close-menu": "Fermer le menu",
    "news.title": "Actualités",
    "news.seo-title": "Actualités - Téléexpertise",
    "press.title": "Presse",
    "press.download-kit": "Télécharger notre dossier de presse",
    "press.kit": "Kit presse",
    "info.title": "Informations",
    "info.seo-title": "Toutes les informations - Téléexpertise",
    "contact-form.title": "Demander une démonstration personnalisée",
    "contact-form.form-description":
      "Notre équipe est à votre disposition pour vous présenter la solution et discuter de sa mise en place dans votre contexte.",
    "contact-form.first-name": "Prénom",
    "contact-form.last-name": "Nom",
    "contact-form.email": "Email",
    "contact-form.phone": "Numéro de téléphone",
    "contact-form.organization": "Organisation",
    "contact-form.role": "Fonction",
    "contact-form.description": "Projet",
    "contact-form.submit-text": "Obtenir plus d’informations",
    "contact-form-thanks.title": "C'est noté !",
    "contact-form-thanks.formSubmitConfirm":
      "Nous avons bien reçu votre demande de démo.",
    "contact-form-thanks.backToHome": "Retourner à l'accueil",
  },
  [LANG_DE_DE]: {
    "seo.title": "Omnidoc, Telekonsil Plattform",
    "seo.description":
      "Omnidoc ist eine Telekonsil Plattform für Gesundheitsfachkräfte und medizinische Einrichtungen, die den Austausch über den Befund eines Patientenfalls verbessern, sichern und effizienter gestalten möchte.",
    "news.title": "Aktuelles",
    "news.seo-title": "Aktuelles",
    "contact-form.title": "Eine persönliche Beratung anfragen",
    "contact-form.form-description":
      "Unser Team steht Ihnen gerne zur Verfügung, um Ihnen Omnidoc vorzustellen und die Umsetzung in Ihrem Kontext zu besprechen.",
    "contact-form.first-name": "Vorname",
    "contact-form.last-name": "Nachname",
    "contact-form.email": "E-Mail",
    "contact-form.phone": "Telefonnummer",
    "contact-form.organization": "Organisation",
    "contact-form.role": "Funktion",
    "contact-form.description": "Projekt",
    "contact-form.submit-text": "Informationen erhalten",
    "contact-form-thanks.title": "Vielen Dank!",
    "contact-form-thanks.formSubmitConfirm":
      "Wir haben Ihre Anfrage erhalten und melden uns in kürze.",
    "contact-form-thanks.backToHome": "Zurück zur Homepage",
  },
};

translations[LANG_FR_BE] = translations[LANG_FR_FR];
translations[LANG_FR_CA] = translations[LANG_FR_FR];

import * as React from "react";
import { transformLink, isLinkRelative, SmartLink } from "../../utils";
import { t } from "../../translations";
import pressKitIcon from "../../images/kit-presse.svg";

const formatUrl = href => {
  const transformedLink = transformLink(href);
  if (isLinkRelative(transformedLink)) {
    return transformedLink.split("/").pop();
  }
  return href;
};

const categoryTextMap = Object.freeze({
  press: "Dans la presse",
  communique: "Communiqué",
});

export const PressLayout = ({ posts, locale }) => {
  return (
    <div className="max-w-6xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <h1 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl">
        {t(locale, "press.title")}
      </h1>
      <div className="pt-10 lg:grid lg:gap-16 lg:grid-cols-3 lg:gap-x-16 lg:gap-y-12">
        <div className="lg:mt-10 mb-10 lg:col-span-1 text-center lg:order-last">
          <a
            href="https://drive.google.com/drive/folders/1pFgCTGwORfV7eBnue2T4uJmNSIekqSE8?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden px-12 py-6 bg-action-100">
              <div className="flex-1 bg-action-100 p-6 flex flex-col justify-between text-center">
                <div className="flex-1">
                  <p className="text-xl font-semibold text-gray-900">
                    {t(locale, "press.kit")}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {t(locale, "press.download-kit")}
                  </p>
                </div>
                <div className="flex-shrink-0 flex mx-auto my-auto items-center justify-center h-22 w-22 rounded-md bg-action-100 text-action-500 sm:h-24 sm:w-24">
                  <img
                    src={pressKitIcon}
                    className="h-14 w-14"
                    aria-hidden="true"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="lg:col-span-2">
          {posts.nodes.map(post => {
            const { title, link, publishedAt, category, id } = post;
            return (
              <article className="max-w-3xl mx-auto py-8 bg-white" key={id}>
                <SmartLink href={link}>
                  <div className="mb-2">
                    <span className="text-gray-500">{publishedAt}</span>
                    <span className="font-hairline ml-4 mr-1">#</span>
                    <span className="text-gray-900">
                      {categoryTextMap[category]}
                    </span>
                  </div>
                  <div className="text-xl font-semibold text-gray-900 my-2">
                    {title}
                  </div>
                  <div className="hover:underline text-action truncate">
                    {formatUrl(link)}
                  </div>
                </SmartLink>
              </article>
            );
          })}
        </div>
      </div>
    </div>
  );
};

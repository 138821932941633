import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import React, { Fragment } from "react";
import logo from "../logo.svg";
import { LocaleSelector, MobileLocaleSelector } from "../LocaleSelector";
import { LANGUAGE_TO_HOMEPAGE, LANG_EN_CA } from "../../../constants";
import { t } from "../../../translations";

const locale = LANG_EN_CA;

export const Navigation = (
  <Popover className="z-50 w-full top-0">
    <div className="flex justify-between items-center px-4 py-3 sm:px-6 lg:justify-start lg:space-x-10 min-h-[62px]">
      <div>
        <Link to={LANGUAGE_TO_HOMEPAGE[locale] || "/"} className="flex">
          <span className="sr-only">Omnidoc</span>
          <img className="h-4 w-auto sm:h-4 mb-0" src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="-mr-2 -my-2 lg:hidden">
        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-action-500">
          <span className="sr-only">{t(locale, "navigation.open-menu")}</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </Popover.Button>
      </div>
      <div className="hidden lg:flex-1 lg:flex lg:items-center lg:justify-between">
        <div className="items-center">
          {/*
          <Link
            to="/ca/en/news"
            className="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            News
          </Link>
            */}
        </div>

        <div className="flex items-center lg:ml-12">
          {/* <Link
            to="/ca/en/ask-a-demo"
            className="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Request a demo
          </Link> */}
          {/* <a
            href="https://app.omnidoc.ca/login?lang=en"
            className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-action-600 hover:bg-action-700"
          >
            Log in
          </a> */}
          <LocaleSelector locale={locale} />
        </div>
      </div>
    </div>

    <Transition
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <img className="h-5 w-auto sm:h-5 mb-0" src={logo} alt="Logo" />
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-action-500">
                  <span className="sr-only">
                    {t(locale, "navigation.close-menu")}
                  </span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="py-6 px-5">
            <div className="grid grid-cols-2 gap-4">
              {/*

              <Link
                to="/ca/en/actualites"
                className="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Actualités
              </Link>
              */}
            </div>
            <div className="mt-6">
              {/* <a
                href="https://app.omnidoc.ca/login?lang=en"
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-action-600 hover:bg-action-700"
              >
                Se&nbsp;connecter
              </a> */}
              {/* <p className="mt-6 text-center text-base font-medium text-gray-500">
                Want to try our solutions ?{" "}
                <Link
                  to="/ca/en/request-a-demo"
                  className="text-action-600 hover:text-action-500"
                >
                  Request a demo
                </Link>
              </p> */}
            </div>
            <MobileLocaleSelector locale={locale} />
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
);
